<template>
  <div v-loading.fullscreen.lock="loading" class="elv-report-detail">
    <div v-if="isShowDetailHeader" class="elv-report-header">
      <DetailHeader id="header" />
    </div>

    <div class="elv-report-tab-button">
      <DetailProgressStatus />
    </div>
  </div>
  <div id="elv-report-detail-container" class="elv-report-detail-container">
    <div
      v-if="isStoppingProject || (isShowDetailHeader && subscribeStatus === 'EXPIRED')"
      class="elv-report-detail-content-locked"
    >
      <ProjectLocked :isLocked="!isStoppingProject" />
    </div>
    <router-view v-else-if="isShowDetailHeader && subscribeStatus !== 'EXPIRED'" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { isMobile } from '@/lib/utils'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import { downloadFile } from '@/lib/download'
import { isEmpty, includes, find } from 'lodash-es'
import { ProjectReviewStatus } from '#/ProjectTypes'
import DetailHeader from './components/DetailHeader.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import ProjectLocked from '@/components/Project/Locked.vue'
import { useWebSocketHook } from '@/hooks/useWebsocketHook'
import { useAccountStore } from '@/stores/modules/accounts'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import DetailProgressStatus from './components/DetailProgressStatus.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const projectStore = useProjectStore()
const userGlobalStore = useUserGlobalStore()
const menuGlobalStore = useMenuGlobalStore()
const tableConfigStore = useReportsTableConfigStore()
const { loading } = storeToRefs(entityStore)

const reconciliationStore = useReconciliationStore()
const isShowDetailHeader = ref(false)
const { entityDetail } = storeToRefs(entityStore)
const { cookies } = useCookies()

// eslint-disable-next-line no-unused-vars
let connect = () => {}
let disconnect = () => {}

const entityId = computed(() => {
  return (route.params?.entityId as string) ? (route.params?.entityId as string) : String(route.query?.entityId)
})

const headerWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const onMessage = async (message: any) => {
  if (message.action !== 'PROGRESS_STATUS') {
    return
  }

  switch (message.data.type) {
    case 'SOURCE_FETCHING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.syncingSourcesSuccess'))
        if (route.name === 'entity-accounts-treasury') {
          // const { entityAccountId, sourceId, providerId } = message.data
          const accountFilter: any =
            accountStore.accountFilterList.find((item: any) => {
              return item.entityId === entityId.value
            }) ?? {}
          const params = {
            date: accountStore.datetime,
            subGroup: 'NONE',
            sort: 'BALANCE',
            combineMasterSubAccount: accountStore.combineMasterSubAccount,
            ...accountFilter?.data
          }
          accountStore.treasuryAccountTableLoading = true
          await accountStore.fetchEntityRecentlyBalanceList(entityId.value, params)
          await tableConfigStore.getProcessTableConfig(
            'accounts',
            ['', 'accountName', 'Datasource provider'],
            'left',
            params
          )
          accountStore.treasuryAccountTableLoading = false
          emitter.emit('resetAccountList', false)
        }
        projectStore.fetchProjectDetail(entityStore.entityDetail?.projectId)
      } else {
        ElMessage.error(t('progressStatusToast.syncingSourcesError'))
      }
      break
    case 'SOURCE_DELETING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.deletingSourcesSuccess'))
        projectStore.fetchProjectDetail(entityStore.entityDetail?.projectId)
      } else {
        ElMessage.error(t('progressStatusToast.deletingSourcesError'))
      }
      break
    case 'IMPORT_ENTITY_ACCOUNT':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.importingAccountSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.importingAccountError'))
      }
      break
    case 'TRANCATION_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.transferAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.transferAutomatingError'))
      }
      break
    case 'TRADE_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.tradeAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.tradeAutomatingError'))
      }
      break
    case 'GAIN_LOSS_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.gainLossAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.gainLossAutomatingError'))
      }
      break
    case 'COUNTERPARTY_TRANSFORMER_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.counterpartyAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.counterpartyAutomatingError'))
      }
      break
    case 'BUSINESS_DATA_AUTOMATING':
    case 'DERIVATIVE_FACT_TRANSFORMER_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.businessDataAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.businessDataAutomatingError'))
      }
      if (route.name === 'entity-transactions-automation') {
        emitter.emit(
          'onResetBusinessDataRuleList',
          message.data.type === 'BUSINESS_DATA_AUTOMATING' ? 'businessData' : 'valuation'
        )
      }
      break
    case 'DERIVATIVE_ADJUSTMENT_AUTOMATING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.derivativeAdjustmentAutomatingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.derivativeAdjustmentAutomatingError'))
      }
      break
    case 'POSTING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.postingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.postingError'))
      }
      break
    case 'JOURNAL_GROUP':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.journalGroupConfigExecuteSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.journalGroupConfigExecuteError'))
      }
      break
    case 'RECALCULATE_COST_BASIS':
    case 'RECALCULATE_ADJUSTMENT':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.recalculateCostBasisSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.recalculateCostBasisError'))
      }
      break
    case 'REPORTING':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.reportingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.reportingError'))
      }
      break
    case 'REFRESH_BALANCE':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.refreshBalanceSuccess'))
        if (route.name === 'entity-accounts-treasury') {
          // const { entityAccountId, sourceId, providerId } = message.data
          emitter.emit('resetAccountList')
        }
      } else {
        ElMessage.error(t('progressStatusToast.refreshBalanceError'))
      }

      break
    case 'ENTITY_ACCOUNT_DELETING':
      if (message.data.status === 'SUCCESS') {
        projectStore.fetchProjectDetail(entityStore.entityDetail?.projectId)
        ElMessage.success(t('progressStatusToast.accountDeletingSuccess'))
      } else {
        ElMessage.error(t('progressStatusToast.accountDeletingError'))
      }

      break
    case 'RECONCILIATION_TASK_MATCH':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(
          t('progressStatusToast.reconciliationTaskExecutedSuccess', { name: message.data?.taskName || '' })
        )
      } else {
        ElMessage.error(
          t('progressStatusToast.reconciliationTaskExecutedError', { name: message.data?.taskName || '' })
        )
      }
      if (
        route.params?.reconciliationTaskId &&
        message.data.reconciliationTaskId === route.params?.reconciliationTaskId
      ) {
        // 刷新对账任务详情
        reconciliationStore.fetchReconciliationTaskDetail(entityId.value, message.data.reconciliationTaskId)
      }

      if (
        message.data.status === 'SUCCESS' &&
        route.params?.reconciliationTaskId &&
        message.data.reconciliationTaskId === route.params?.reconciliationTaskId &&
        route?.name === 'entity-reconciliation-matches'
      ) {
        emitter.emit('getReconciliationMatchedList')
      }
      break
    case 'RECONCILIATION_TASK_LOAD':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(
          t('progressStatusToast.reconciliationTaskRefreshedSuccess', { name: message.data?.taskName || '' })
        )
      } else {
        ElMessage.error(
          t('progressStatusToast.reconciliationTaskRefreshedError', { name: message.data?.taskName || '' })
        )
      }
      if (message.data.reconciliationTaskId !== route.params?.reconciliationTaskId) return
      // 刷新对账任务详情
      reconciliationStore.fetchReconciliationTaskDetail(entityId.value, message.data.reconciliationTaskId)
      reconciliationStore.fetchReconciliationDataSetList(entityId.value, message.data.reconciliationTaskId)
      if (message.data.status === 'SUCCESS' && route.params?.reconciliationTaskId) {
        // 数据集列表 - 刷新
        if (route?.name === 'entity-reconciliation-data-sets') {
          emitter.emit('reconciliationTaskDataSetsInit')
        }
        // 对账明细列表 - 刷新
        if (route?.name === 'entity-reconciliation-details') {
          emitter.emit('reconciliationDetailsDataInit')
        }
        // 对账报告列表 - 刷新
        if (route?.name === 'entity-reconciliation-report') {
          emitter.emit('getReconciliationReportList')
        }
      }
      break
    case 'RECONCILIATION_TASK_DELETE':
      if (message.data.status === 'SUCCESS') {
        ElMessage.success(t('progressStatusToast.reconciliationTaskDeletedSuccess'))
        if (
          route.params?.reconciliationTaskId &&
          message.data.reconciliationTaskId === route.params?.reconciliationTaskId
        ) {
          router.push({ name: 'entity-reconciliation-tasks', params: { entityId: entityId.value } })
        } else if (message.data.reconciliationTaskId && route?.name === 'entity-reconciliation-tasks') {
          emitter.emit('reconciliationTaskDataInit')
        }
      } else {
        ElMessage.error(t('progressStatusToast.reconciliationTaskDeletedError', { name: message.data?.taskName || '' }))
        if (
          route.params?.reconciliationTaskId &&
          message.data.reconciliationTaskId === route.params?.reconciliationTaskId
        ) {
          // 刷新对账任务详情
          reconciliationStore.fetchReconciliationTaskDetail(entityId.value, message.data.reconciliationTaskId)
        } else if (message.data.reconciliationTaskId && route?.name === 'entity-reconciliation-tasks') {
          emitter.emit('reconciliationTaskDataInit')
        }
      }
      break
    case 'FULL_PROCESS_TASK':
      if (message.data.status === 'SUCCESS') {
        entityDetail.value.progress.changeCheckpointStatus = 'FINISHED'
      }
      break
    case 'EXPORT_DATA':
      if (message.data?.data?.userId === userGlobalStore.user?.userId) {
        if (message.data.status === 'SUCCESS') {
          ElMessage.success(t('progressStatusToast.exportDataSuccess'))
          downloadFile(message.data?.data?.downloadUrl)
        } else {
          ElMessage.error(t('progressStatusToast.exportDataFailed'))
        }
      }
      entityStore.updateExportTaskHistory(entityId.value, message.data?.data?.type || '')
      break
    default:
      break
  }
  entityStore.fetchEntityDetail(entityId.value)
}

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const subscribeStatus = computed(() => {
  return find(globalStore.projectList, { projectId: entityStore.entityDetail?.projectId })?.subscribeStatus
})

const currentProject = computed(() => {
  return find(globalStore.projectList, { projectId: entityStore.entityDetail?.projectId })
})

const isStoppingProject = computed(() => {
  return currentProject.value && currentProject.value?.projectReview?.status === ProjectReviewStatus.STOP
})

const getWebsocketUrl = () => {
  const baseUrl = entityDetail.value?.websocketUrl
  if (!baseUrl) {
    return ''
  }
  return `${baseUrl}?entityId=${entityId.value}&token=${cookies.get('elv-app-token')}`
}

const connectWebsocket = () => {
  const url = getWebsocketUrl()
  if (!url) {
    return
  }
  const result = useWebSocketHook({ url, onMessage })
  connect = result.connect
  disconnect = result.disconnect
  connect()
}

const onIntervalFetchEntityDetail = async () => {
  if (entityStore.intervalTimer === null) {
    entityStore.intervalTimer = setInterval(async () => {
      try {
        await entityStore.fetchEntityDetail(entityId.value)
      } catch (error: any) {
        isShowDetailHeader.value = true
        if (error?.code === 10001 || error?.code === 19424) {
          if (globalStore.projectList.length) {
            if (globalStore.entityListAll[0]?.entityId !== entityId.value) {
              router.replace({
                name: 'entity',
                params: {
                  entityId:
                    userGlobalStore.user?.navigationSort?.[0]?.entityIds?.[0] ?? globalStore.entityListAll[0]?.entityId
                }
              })
            } else {
              entityStore.entityDetail.entityId = entityId.value
              ElMessage.error(error?.message)
            }
          } else {
            router.replace({ name: 'landingPage' })
          }
        } else if (error?.code === 401) {
          router.push({ name: 'login' })
        }
      }
    }, 15000)
  }
}

watch(entityDetail, (newValue, oldValue) => {
  if (newValue.websocketUrl && newValue.websocketUrl !== oldValue.websocketUrl) {
    console.log('初始化ws')
    connectWebsocket()
    if (entityStore.intervalTimer !== null) {
      clearInterval(entityStore.intervalTimer)
      entityStore.intervalTimer = null
    }
  }

  if (newValue.entityId !== oldValue?.entityId) {
    if (entityStore.intervalTimer !== null) {
      clearInterval(entityStore.intervalTimer)
      entityStore.intervalTimer = null
    }
    onIntervalFetchEntityDetail()
  }
})

watch(
  [() => entityId.value],
  async () => {
    if (entityId.value !== '' && entityId.value !== 'undefined') {
      isShowDetailHeader.value = false
      if (entityId.value !== entityStore.entityDetail?.entityId) {
        try {
          await entityStore.entityInit(entityId.value)
          projectStore.fetchProjectDetail(entityStore.entityDetail?.projectId)
        } catch (error: any) {
          if (error?.code === 19424) {
            entityStore.entityDetail.entityId = entityId.value
            ElMessage.error(error?.message)
          }
        } finally {
          isShowDetailHeader.value = true
        }
      } else {
        isShowDetailHeader.value = true
      }
    }
  },
  { immediate: true }
)

watch(
  () => route.params,
  (newValue, oldValue) => {
    if (newValue.entityId !== oldValue?.entityId && newValue.entityId) {
      disconnect()
      connectWebsocket()
    }
  },
  { immediate: true }
)

watch(
  () => route,
  () => {
    if (
      route?.path?.indexOf('entity') > -1 &&
      route.params?.entityId &&
      route.params?.entityId === entityStore.entityDetail?.entityId
    ) {
      projectStore.fetchProjectDetail(entityStore.entityDetail?.projectId)
    }
  },
  { immediate: true, deep: true }
)

onUnmounted(() => {
  disconnect()
  clearInterval(entityStore.intervalTimer)
  entityStore.intervalTimer = null
})

watchEffect(() => {
  if (entityStore.entityDetail?.entityId !== '') {
    if (
      (route.params?.entityId as string) &&
      !route.params?.projectId &&
      !isEmpty(currentEntityPermission.value) &&
      entityStore.entityMemberList?.length
    ) {
      if (route.matched.length === 2) {
        router.push({ name: 'entity' })
      } else if (route.name !== 'reports-balance-sheet') {
        const tableRouteNameList = [
          'reports-balance-sheet',
          'reports-income-statement',
          'reports-cash-flow-statement',
          'reports-significant-holdings',
          'reports-restrictions',
          'reports-roll-forward'
        ]
        if (
          includes(tableRouteNameList, route.name) &&
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.report?.view
        ) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        switch (route.name) {
          case 'entity-accounts-sources':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.dataSource?.view
            ) {
              console.log('dataSource')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-general-ledger':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.generalLedger?.view
            ) {
              console.log('generalLedger')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'entity-accounts-treasury':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.entityAccount?.view
            ) {
              console.log('treasuryAccount', currentEntityPermission.value)
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'entity-accounts-contacts':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.counterparty?.view
            ) {
              console.log('counterparty')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'entity-ledger-journals':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.journal?.viewList
            ) {
              console.log('journal')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'entity-transactions-list':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.transaction?.viewList
            ) {
              console.log('transaction')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'entity-transactions-automation':
            if (
              ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
              !currentEntityPermission.value?.automationRule?.view
            ) {
              console.log('automationRule')
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          default:
            break
        }
        router.push({ name: String(route.name), query: route.query })
      } else {
        if (
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.report?.view
        ) {
          console.log('report')
          ElMessage.warning(t('message.noPermission'))
          return
        }
        router.push({ name: 'reports-balance-sheet' })
      }
    }
  }
})
</script>

<style lang="scss">
.elv-layout-main {
  padding: 0 !important;
}

.elv-report-detail {
  position: relative;
  display: flex;
  justify-content: space-between;

  .elv-report-header {
    width: v-bind('headerWidth');
    box-sizing: border-box;
    border-bottom: 1px solid #dde1e6;
  }

  .elv-report-tab-button {
    position: absolute;
    right: 20px;
    height: 48px;
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.elv-report-detail-container {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-report-detail-content-locked {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>
